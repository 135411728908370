import Layout from "./component/layout/Layout";

export default function Error() {

    setTimeout(window.location.href = '/', 10000);

    return (
        <Layout>
            <div className="min-h-screen flex flex-grow items-center justify-center">
            <div className="text-center shadow-xl card bg-com">
                <h1 className="mb-4 text-8xl md:text-5xl font-bold">404</h1>
                <p className="text-gray-500">Oops! The page you are looking for could not be found.</p>
                <a href="/" className="mt-4 btn-prim"> Home </a>
            </div>
        </div>
        </Layout>
    )
}