import { jwtDecode } from "jwt-decode";
import Layout from "../../component/layout/Layout";
import { useState } from "react";
import Loader from "../../component/Loader";
import toast from "react-hot-toast";

type decofetoken = {
    email: string,
    exp: Number,
    iat: Number,
    id: string,
    name: string
}

interface regisProdInp {
    model: string,
    serial_no: string,
    purchase_place: string,
    purchase_date: string,
}

export default function Register() {
    const [regisProdInp, setregisProdInp] = useState<regisProdInp>({
        model: '',
        serial_no: '',
        purchase_place: '',
        purchase_date: '',
    })
    const [bill, setbill] = useState<File | null>(null);
    const [loader, setloader] = useState<boolean>(false);
    const [scaner, setscaner] = useState<boolean>(false);

    const token = window.localStorage.getItem('usertoken') as string;
    const decodeToken: decofetoken = jwtDecode(token);


    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setbill(e.target.files[0]);
        }
    };

    const regisProd = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setloader(true);
        const formdata = new FormData();
        formdata.append('name', decodeToken?.name);
        formdata.append('userID', decodeToken?.id);
        formdata.append('email', decodeToken?.email);
        formdata.append('model', regisProdInp.model);
        formdata.append('serial_no', regisProdInp.serial_no);
        formdata.append('purchase_place', regisProdInp.purchase_place);
        formdata.append('purchase_date', regisProdInp.purchase_date);
        formdata.append('purchase_proof', bill as File);

        await fetch(`${process.env.REACT_APP_BACKENDURL}/product-register/register`, {
            method: 'POST',
            body: formdata
        }).then(res => res.json())
            .then(res => {
                console.log(res)
                if (res.status === 200) {
                    window.location.href = '/thankyou';
                    setloader(false);
                }
                else if (res.status === 404) {
                    toast.error(res.message);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    return (
        <Layout>
            <section className=''>
                <div className="w-full flex justify-center items-center min-h-screen p-1.5">
                    <div className='md:w-[750px] w-full mx-auto bg-com card relative mt-10' >
                        {
                            loader ? <Loader /> : null
                        }
                        <div className="md:w-[400px] w-full">



                            <div className="form-head text-center mb-6">
                                <h1 className="text-4xl text-gray-100 font-bold mb-2">
                                    Register a Product
                                </h1>
                                <p className='text-gray-300 text-[0.9rem]'>{decodeToken?.name + ','} Just take a few minutes to fill out the form.</p>
                            </div>

                            <div className="form-div">

                                <form onSubmit={regisProd} encType="multipart/form-data" >
                                    <div className='flex flex-col gap-1.5' >
                                        <label className='text-gray-400 text-[0.8rem]' htmlFor="model">Enter Model Number*</label>
                                        <input type="text" placeholder="Enter Model Number*" name="model" autoComplete="off" required className="form-control bg-transparent" onChange={(e) => setregisProdInp({ ...regisProdInp, model: e.target.value })} />
                                    </div>
                                    <div className='flex flex-col gap-1.5' >
                                        <label className='text-gray-400 text-[0.8rem]' htmlFor="serial_no">Serial Number*</label>
                                        <input type="text" placeholder="Serial Number*" name="serial_no" autoComplete="off" required className="form-control bg-transparent" onChange={(e) => setregisProdInp({ ...regisProdInp, serial_no: e.target.value })} />
                                    </div>
                                    <div className='flex flex-col gap-1.5' >
                                        <label className='text-gray-400 text-[0.8rem]' htmlFor="purchase_place">Place of Purchase*</label>
                                        <input type="text" placeholder="Place of Purchase*" name="purchase_place" autoComplete="off" required className="form-control bg-transparent" onChange={(e) => setregisProdInp({ ...regisProdInp, purchase_place: e.target.value })} />
                                    </div>
                                    <div className='flex flex-col gap-1.5' >
                                        <label className='text-gray-400 text-[0.8rem]' htmlFor="purchase_date">Purchased Date*</label>
                                        <input type="date" placeholder="Purchased Date*" name="purchase_date" autoComplete="off" required className="form-control bg-transparent" onChange={(e) => setregisProdInp({ ...regisProdInp, purchase_date: e.target.value })} />
                                    </div>
                                    <div className='flex flex-col gap-1.5' >
                                        <label className='text-gray-400 text-[0.8rem]' htmlFor="purchase_proof">Proof of Purchase*</label>
                                        <div className="input_container">
                                            <input type="file" id="fileUpload" placeholder="Proof of Purchase*" name="purchase_proof" autoComplete="off" required className="form-control bg-transparent"
                                                onChange={handleFileChange} />
                                        </div>

                                    </div>

                                    <div className="btn">
                                        <button type="submit" className='btn-prim w-full mt-2.5 mb-4'>Submit</button>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}
