import { useGoogleLogin } from '@react-oauth/google';
import { useState } from 'react';
import toast from 'react-hot-toast';
import Loader from './Loader';

export default function Googlelogin({title}:{title:string}) {

    const [loading, setloading] = useState<boolean>(false)
    const login = useGoogleLogin({
        onSuccess: async (codeResponse: any) => {
            setloading(true);
            await fetch(`https://www.googleapis.com/oauth2/v3/userinfo?access_token=${codeResponse.access_token}`,{
                method:'GET',
            }).then(res => res.json())
            .then(res => {
              console.log(res)
              googleAfterLogin(res);
            })
        }
    });

    const googleAfterLogin = async (data:any) => {
        setloading(true);
        await fetch(`${process.env.REACT_APP_BACKENDURL}/users/googlelogin`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        }).then(res => res.json())
          .then(res => {
            console.log(res)
            if (res.data.status === 200) {
              localStorage.setItem('usertoken', res.data.token);
              toast.success(res.data.message);
              window.location.href = '/register-product';
              setloading(false);
            }
            else if (res.data.status === 404) {
              toast.error(res.data.message);
              setloading(false);
            }
            else if (res.data.status === 401) {
              toast.error(res.data.message);
              setloading(false);
            }
          })
          .catch(err => {
            console.log(err);
          })
        }

    return (
        <>
        {
            loading ? <Loader /> : null
        }
            <button onClick={() => login()} className="google-btn">
                <img className="w-5 h-5" src="./assets/img/google.svg" loading="lazy" alt="google logo" />
                <span>{title} with Google</span>
            </button>
        </>
    )
}
