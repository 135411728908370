import Layout from "../../component/layout/Layout";
import { useState } from "react";
import toast from "react-hot-toast";
import Googlelogin from "../../component/GoogleLogin";

export default function Signup() {
  const [signupInp, setsignupInp] = useState({
    email: '',
    password: '',
    name: '',
    code: ''
  });

  const [otpPart, setotpPart] = useState<boolean>(false);

  const signUpBeforeOtp = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await fetch(`${process.env.REACT_APP_BACKENDURL}/users/registerotp`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(signupInp)
    }).then(res => res.json())
      .then(res => {
        console.log(res)
        if (res.status === 200) {
          toast.success(res.message);
          setotpPart(true);
        }
        else if (res.status === 400) {
          toast.error(res.message);
        }
      })
      .catch(err => {
        console.log(err);
      })
  }

  const signupAfterOtp = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log(signupInp.code)
    if (signupInp.code !== '') {
      await fetch(`${process.env.REACT_APP_BACKENDURL}/users/add`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(signupInp)
      }).then(res => res.json())
        .then(res => {
          if (res.status === 200) {
            toast.success(res.message);
            window.location.href = '/thankyou';
          }
          else if (res.status === 404) {
            toast.error(res.message);
          }
        })
        .catch(err => {
          console.log(err);
        })
    }
    else {
      toast.error('Please Enter a OTP Properly');
    }

  }

  return (
    <Layout>
      <section className='flex items-center min-h-screen '>
        <div className="w-full flex justify-center items-center min-h-screen p-1.5">
          <div className='md:w-[600px] w-full mx-auto bg-com card mt-10 relative'>
            <div className="md:w-[400px] w-full">

              <div className="form-head text-center mb-6">
                <h2 className="text-2xl text-gray-100 font-bold mb-2">
                  Sign Up
                </h2>
              </div>

              <div className='social-login-btn'>

                <Googlelogin title="Sign Up" />

                <div className="sperator my-10">
                  <span className='md:w-[150px] w-full border-b h-[1px] border-gray-700 border-opacity-50'></span>
                  <span className='md:w-[150px] w-full text-center text-gray-500 text-[0.8rem] font-semibold'>Or with email</span>
                  <span className='md:w-[150px] w-full border-b h-[1px] border-gray-700 border-opacity-50'></span>
                </div>

              </div>

              <div className="form-div">
                {
                  !otpPart ?
                    <form onSubmit={signUpBeforeOtp} >

                      <input type="text" placeholder="Name" name="name" autoComplete="off" className="form-control bg-transparent"
                        onChange={(e) => setsignupInp({ ...signupInp, name: e.target.value })} />

                      <input type="email" placeholder="Email" name="email" autoComplete="off" className="form-control bg-transparent"
                        onChange={(e) => setsignupInp({ ...signupInp, email: e.target.value })} />

                      <input type="password" placeholder="Password" name="password" autoComplete="off" className="form-control bg-transparent"
                        onChange={(e) => setsignupInp({ ...signupInp, password: e.target.value })} />

                      <div className="btn">
                        <button type="submit" className='btn-prim w-full mt-2.5 mb-4'>Send OTP</button>
                      </div>

                    </form> :
                    <div className="otp-part">
                      <h2 className="text-xl text-gray-100 font-bold mb-2 text-center">OTP</h2>
                      <p className="text-center text-gray-500 text-[0.9rem] font-semibold mb-3">A OTP has been sent to {signupInp.email} ans submit OTP code below to verify your account.</p>
                      <form onSubmit={signupAfterOtp} >

                        <input type="text" placeholder="OTP" name="code" autoComplete="off" className="form-control bg-transparent"
                          onChange={(e) => setsignupInp({ ...signupInp, code: e.target.value })} />

                        <div className="btn">
                          <button type="submit" className='btn-prim w-full mt-2.5 mb-4'>Submit OTP</button>
                        </div>

                      </form>
                    </div>
                }

                <div className="signup-link text-center text-gray-500 text-[0.9rem] font-medium">
                  Not a Member yet? <a className='link' href="#">Sign up</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
