import Layout from "../../component/layout/Layout";
import { useState } from "react";
import toast from "react-hot-toast";
import Googlelogin from "../../component/GoogleLogin";

export default function Login() {
  const [loginInp, setloginInp] = useState({
    email: '',
    password: ''
  });
  const loginBeforeOtp = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await fetch(`${process.env.REACT_APP_BACKENDURL}/users/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(loginInp)
    }).then(res => res.json())
      .then(res => {
        console.log(res)
        if (res.data.status === 200) {
          localStorage.setItem('usertoken', res.data.token);
          toast.success(res.data.message);
          window.location.href = '/register-product';
        }
        else if (res.data.status === 404) {
          toast.error(res.data.message);
        }
        else if (res.data.status === 401) {
          toast.error(res.data.message);
        }
      })
      .catch(err => {
        console.log(err);
      })
  }



  return (
    <Layout>
      <section className='flex items-center min-h-screen '>
        <div className="w-full flex justify-center items-center min-h-screen p-1.5">
          <div className='md:w-[600px] w-full mx-auto bg-com card relative'>
            <div className="md:w-[400px] w-full">

              <div className="form-head text-center mb-6">
                <h2 className="text-2xl text-gray-100 font-bold mb-2">
                  Sign In
                </h2>
              </div>

              <div className='social-login-btn '>
                <Googlelogin title="Sign In" />

                <div className="sperator my-10">
                  <span className='md:w-[150px] w-full border-b h-[1px] border-gray-700 border-opacity-50'></span>
                  <span className='md:w-[150px] w-full text-center text-gray-500 text-[0.8rem] font-semibold'>Or with email</span>
                  <span className='md:w-[150px] w-full border-b h-[1px] border-gray-700 border-opacity-50'></span>
                </div>

              </div>

              <div className="form-div">
                <form onSubmit={loginBeforeOtp} >
                  <input type="email" placeholder="Email" name="email" autoComplete="off" className="form-control bg-transparent"
                    onChange={(e) => setloginInp({ ...loginInp, email: e.target.value })} />

                  <input type="password" placeholder="Password" name="password" autoComplete="off" className="form-control bg-transparent"
                    onChange={(e) => setloginInp({ ...loginInp, password: e.target.value })} />

                  <div className="btn">
                    <button type="submit" className='btn-prim w-full mt-2.5 mb-4'>Submit</button>
                  </div>
                </form>

                <div className="signup-link text-center text-gray-500 text-[0.9rem] font-medium">
                  Not a Member yet? <a className='link' href="/signup">Sign In</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>

  )
}
