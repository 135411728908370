import { jwtDecode } from 'jwt-decode';
import React, { ReactNode } from 'react'
import { Navigate, Outlet } from 'react-router-dom'

interface ProtectedRouteProps {
  children: ReactNode;
}

const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  let token = window.localStorage.getItem("usertoken") as string;

  if (token) {
    try {
      const decoded = jwtDecode(token);
      const startTokenTime = decoded.iat as number;
      const endTokenTime = decoded.exp as number;

      if (startTokenTime > endTokenTime) {
        // Token is invalid
        localStorage.clear();
        return <Navigate to='/' />;
      } else {
        // Token is valid
        return <>{children}</>;
      }
    } catch (error) {
      // Handle decoding error
      console.error("Error decoding JWT:", error);
      localStorage.clear();
      return <Navigate to='/' />;
    }
  }

  // Redirect to login if no token is found
  return <Navigate to='/' />;
};

export default ProtectedRoute;
