import { Toaster } from "react-hot-toast";
import Header from "../Header";
import { ReactNode } from 'react';

export default function Layout({ children }: { children: ReactNode }) {
  return (
    <>
      <div className="relative">
      <Toaster
        position="bottom-center"
        reverseOrder={false}
      />
      <Header />
      {children}
      </div>
    </>
  )
}
