import Confetti from 'react-confetti'
import Layout from '../../component/layout/Layout';


declare global {
    interface Window {
        $: any;
    }
}

export default function Thankyou() {

    const width = window.$('#thankyou').width();
    const height = window.$('#thankyou').height();

    setTimeout(returnFct, 10000);

    function returnFct() {
        window.localStorage.clear();
        window.location.href = '/';
    }

    return (
        <>
            <Layout>
                <div id="thankyou" className='min-h-screen w-full'>
                    <div className="flex items-center justify-center h-screen">
                        <div className='card bg-com relative z-10'>
                            <div className="flex flex-col items-center space-y-2">
                                <svg xmlns="http://www.w3.org/2000/svg" className="text-green-600 w-28 h-28" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>

                                <h1 className="text-4xl font-bold">Thank You !</h1>
                                <p>Thank you! For support you can also download our app, <strong>‘SPPL Service’</strong> from the play store.</p>
                                <div className='get-play pt-5'>
                                    <a target='_blank' href="https://play.google.com/store/apps/details?id=com.sppl.serviceapp&pcampaignid=web_share">
                                        <img src="./assets/img/get-playstore.png" alt="get-playstore" className='w-40' />
                                    </a>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <Confetti width={width} height={height} />
            </Layout>
        </>
    )
}
