import './App.css';

// import Html5QrcodePlugin from './component/Html5QrcodePlugin';
import Login from './pages/login/Login';
import Register from './pages/register/Register';
import Signup from './pages/signup/Signup';
import Thankyou from './pages/thankyou/Thankyou';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { ReactNode, useEffect, useState } from 'react';
import { jwtDecode } from "jwt-decode";
import Error from './Error';
import ProtectedRoute from './component/ProtectedRoute';
import Demo from './pages/Demo';

function App() {

  return (
    <>
      <Routes>
      {/* <Route path='/' element={<Demo />} /> */}
        <Route path='/' element={<Login />} />
        <Route path='/signup' element={<Signup />} />

          <Route path='/register-product' element={<ProtectedRoute><Register /></ProtectedRoute>} />
          <Route path='/thankyou' element={<ProtectedRoute><Thankyou /></ProtectedRoute>} />
    
        <Route path='*' element={<Error />} />
      </Routes>

    </>
  );
}

export default App;
