export default function Header() {

  return (
    <header className="bg-com w-full max-w-5xl mx-auto rounded-full py-2 px-5 flex items-center justify-between border border-slate-700 absolute top-2 left-1/2 -translate-x-1/2 z-10 ">
      <div className="logo-part flex justify-center">
        <img src="./assets/img/logo.png" alt="logo" className="logo-part-img" />
      </div>
      <div className="nav">
        <ul>
          <li className="text-gray-400 text-[0.9rem] font-medium"><a href="https://shopsppl.in/">Shop</a></li>
        </ul>
      </div>
    </header>
  )
}
